<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="hazard"
          :title="'유해인자 결과 목록'"
          tableId="guideBook"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :columnSetting="false"
          :merge="grid.merge"
          @linkClick="linkClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'work-measure-result-factor-tab',
  props: {
    searchParam: {
      type: Object,
      default: () => ({
        plantCd: '',
        measQuarter: null,
        measYear: '',
        startYmd: '',
        endYmd: '',
        speHealthFlag: null,
        exposureFlag: null,
        hazardClassFstCd: 'HCF0000005',
        hazardCd: '',
        hazardName: '',
        year: ['', ''],
      }),
    },
    tabParam: {
      type: Object,
      default: function() {
        return {
          title: '화학적인자',
        }
      },
    },
    count: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'hazardName' },
        ],
        columns: [],
        data: [],
      },
      workFlagItems: [
        { code: 'Y', codeName: '관련' },
        { code: 'N', codeName: '미관련' },
      ],
      year: ['', ''],
      colorItems: [],
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.sai.workmeas.result.factor.url;
      // this.$comm.getStepItems('IBM_STEP_CD').then(_result => {
      //   this.colorItems = _result
      // });
      this.setHeader();
      this.getList();
    },
    getList() {
      if (this.year && this.year.length > 0) {
        this.searchParam.startYmd = this.searchParam.year[0];
        this.searchParam.endYmd = this.searchParam.year[1];
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    setHeader() {
      if (this.searchParam.hazardClassFstCd === 'HCF0000005' || this.searchParam.hazardClassFstCd === 'HCF0000010') {
        this.grid.merge = [
          { index: 0, colName: 'hazardName' },
          { index: 1, colName: 'hazardName' },
          { index: 2, colName: 'hazardName' },
        ]
        this.grid.columns = [
          {
            name: 'hazardName',
            field: 'hazardName',
            label: '유해인자명',
            align: 'center',
            style: 'width:250px',
            type: "link",
            sortable: true,
          },
          {
            name: 'casNo',
            field: 'casNo',
            label: 'CAS NO.',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'chemName',
            field: 'chemName',
            label: '화학물질명',
            style: 'width:200px',
            align: 'left',
            sortable: true,
          },
          {
            name: 'measYear',
            field: 'measYear',
            label: '측정년도',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'measQuarterName',
            field: 'measQuarterName',
            label: '분기',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'processName',
            field: 'processName',
            label: 'LBLPROCESS',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: 'LBLDEPT',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'workPlace',
            field: 'workPlace',
            label: '단위작업장소',
            align: 'center',
            sortable: true,
          },
          {
            name: 'speHealthFlagName',
            field: 'speHealthFlagName',
            label: '특수건강검진여부',
            align: 'center',
            style: 'width:70px',
            sortable: true,
          },
          {
            name: 'exposureFlagName',
            field: 'exposureFlagName',
            label: '노출기준초과여부',
            style: 'width:70px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'workContents',
            field: 'workContents',
            label: '작업내용',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'workCount',
            field: 'workCount',
            label: '근로자수',
            align: 'center',
            sortable: true,
          },
          {
            name: 'shiftType',
            field: 'shiftType',
            label: '근로형태_시간',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'occurType',
            field: 'occurType',
            label: '발생형태_주기',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'occurTime',
            field: 'occurTime',
            label: '유해인자발생시간',
            align: 'center',
            sortable: true,
          },
          {
            name: 'measPositionName',
            field: 'measPositionName',
            label: '측정위치',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'measCount',
            field: 'measCount',
            label: '측정횟수',
            align: 'center',
            sortable: true,
          },
          {
            name: 'measValue',
            field: 'measValue',
            label: '측정치',
            align: 'center',
            sortable: true,
          },
          {
            name: 'twaPrev',
            field: 'twaPrev',
            label: '시간가중평균치(전회)',
            align: 'center',
            sortable: true,
          },
          {
            name: 'twaCurr',
            field: 'twaCurr',
            label: '시간가중평균치(금회)',
            align: 'center',
            sortable: true,
          },
          {
            name: 'exposureStandard',
            field: 'exposureStandard',
            label: '노출기준',
            align: 'center',
            sortable: true,
          },
          {
            name: 'measMethod',
            field: 'measMethod',
            label: '측정_분석방법',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
        ];
      } else {this.grid.merge = [
          { index: 0, colName: 'hazardName' },
          
        ]
        this.grid.columns = [
          {
            name: 'hazardName',
            field: 'hazardName',
            label: '유해인자명',
            align: 'center',
            style: 'width:250px',
            type: "link",
            sortable: true,
          },
          {
            name: 'measYear',
            field: 'measYear',
            label: '측정년도',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'measQuarterName',
            field: 'measQuarterName',
            label: '분기',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'processName',
            field: 'processName',
            label: 'LBLPROCESS',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: 'LBLDEPT',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'workPlace',
            field: 'workPlace',
            label: '단위작업장소',
            align: 'center',
            sortable: true,
          },
          {
            name: 'speHealthFlagName',
            field: 'speHealthFlagName',
            label: '특수건강검진여부',
            align: 'center',
            style: 'width:70px',
            sortable: true,
          },
          {
            name: 'exposureFlagName',
            field: 'exposureFlagName',
            label: '노출기준초과여부',
            style: 'width:70px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'workContents',
            field: 'workContents',
            label: '작업내용',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'workCount',
            field: 'workCount',
            label: '근로자수',
            align: 'center',
            sortable: true,
          },
          {
            name: 'shiftType',
            field: 'shiftType',
            label: '근로형태_시간',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'occurType',
            field: 'occurType',
            label: '발생형태_주기',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'occurTime',
            field: 'occurTime',
            label: '유해인자발생시간',
            align: 'center',
            sortable: true,
          },
          {
            name: 'measPositionName',
            field: 'measPositionName',
            label: '측정위치',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'measCount',
            field: 'measCount',
            label: '측정횟수',
            align: 'center',
            sortable: true,
          },
          {
            name: 'measValue',
            field: 'measValue',
            label: '측정치',
            align: 'center',
            sortable: true,
          },
          {
            name: 'twaPrev',
            field: 'twaPrev',
            label: '시간가중평균치(전회)',
            align: 'center',
            sortable: true,
          },
          {
            name: 'twaCurr',
            field: 'twaCurr',
            label: '시간가중평균치(금회)',
            align: 'center',
            sortable: true,
          },
          {
            name: 'exposureStandard',
            field: 'exposureStandard',
            label: '노출기준',
            align: 'center',
            sortable: true,
          },
          {
            name: 'measMethod',
            field: 'measMethod',
            label: '측정_분석방법',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
        ]
      }
    },
    linkClick(result, col) {
      if (col && col.name === 'hazardName') {
        this.popupOptions.target = () => import(`${"./harmfulFactorsDetail.vue"}`);
        this.popupOptions.title = '유해인자 상세';
        this.popupOptions.param = {
          hazardCd: result ? result.hazardCd : '',
        };
      } else if (col && col.name === 'measYear') {
        this.popupOptions.target = () => import(`${"./workMeasureResultDetail.vue"}`);
        this.popupOptions.title = '작업환경측정결과 상세';
        this.popupOptions.param = {
          workMeasurementResultId: result ? result.workMeasurementResultId : '',
          disabled: true,
        };
      }
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
